import React from "react";
import Image from "../../assets/image/svg/gogreen.svg";
import "./Usecasesv2.css";
import transparency from "../../assets/image/svg/transparency.svg";
import usersegmentation from "../../assets/image/svg/usersegmentation.svg";
import future from "../../assets/image/svg/future.svg";
import gogreen from "../../assets/image/svg/gogreen.svg";

import pfm from "../../assets/image/usecase/personal_finance_management.webp";
import ila from "../../assets/image/usecase/instant_loan_approval.webp";
import dtl from "../../assets/image/usecase/delightful_transaction_list.webp";
import cda from "../../assets/image/usecase/credit_decisioning_automation.webp";
import bfm from "../../assets/image/usecase/business_finance_management.webp";
const Usecasesv2 = () => {
  return (
    <section
      className="uc-section-1"
      style={{ marginBottom: "10vh" }}
      id="use-cases"
    >
      <div className="uc-section-2">
        <div>
          <p className="section-header">Use cases</p>
          <h2 className="section-h2">
            Power your fintech products with Genify
          </h2>
        </div>
        <div className="uc-grid">
          {/*grid*/}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            data-aos="fade-right"
            data-aos-duration="1300"
            data-aos-delay="400"
          >
            <div>
              <img
                src={dtl}
                style={{ height: "13vh", marginRight: "23px", position: "relative" }}
                alt="delightful transaction list"
              />
            </div>
            <div>
              {/*img */}
              <div></div> {/*spacer */}
              <h4 className="uc-section-h4">Delightful Transaction List</h4>
              <p className="uc-section-p">
                Offer the most visually appealing transaction feed.
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            data-aos="fade-right"
            data-aos-duration="1300"
            data-aos-delay="500"
          >
            <div>
              <img
                src={pfm}
                style={{ height: "13vh", marginRight: "23px", position: "relative" }}
                alt="personal finance management"
              />
            </div>
            <div>
              {/*img */}
              <div></div> {/*spacer */}
              <h4 className="uc-section-h4">Personal Finance Management</h4>
              <p className="uc-section-p">
                {" "}
                Help people understand where their money goes.
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            data-aos="fade-right"
            data-aos-duration="1300"
            data-aos-delay="600"
          >
            <div>
              <img
                src={bfm}
                style={{ height: "13vh", marginRight: "23px", position: "relative" }}
                alt="business finance management"
              />
            </div>
            <div>
              {/*img */}
              <div></div> {/*spacer */}
              <h4 className="uc-section-h4">Business Finance Management</h4>
              <p className="uc-section-p">
                Unlock cash flow transparency for business.
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            data-aos="fade-right"
            data-aos-duration="1300"
            data-aos-delay="700"
          >
            <div>
              <img
                src={cda}
                style={{ height: "13vh", marginRight: "23px", position: "relative" }}
                alt="credit decisioning automation"
              />
            </div>
            <div>
              {/*img */}
              <div></div> {/*spacer */}
              <h4 className="uc-section-h4">Credit Decisioning Automation</h4>
              <p className="uc-section-p">
                Encode your business rules and decision trees for automated
                lending decisioning.
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            data-aos="fade-right"
            data-aos-duration="1300"
            data-aos-delay="500"
          >
            <div>
              <img
                src={ila}
                style={{ height: "13vh", marginRight: "23px", position: "relative" }}
                alt="instant loan approval"
              />
            </div>
            <div>
              {/*img */}
              <div></div> {/*spacer */}
              <h4 className="uc-section-h4">Instant Loan Approval</h4>
              <p className="uc-section-p">
                {" "}
                Leverage the power of alternative data for underwriting.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Usecasesv2;
