/* eslint no-unused-expressions: "off" */
import React, { useContext, useEffect, useRef } from 'react'
import Seo from '../components/Seo.js'
import UseCases from '../components/UseCases'
import ValueProp from '../components/ValueProp'
import GlobalContext from '../context/GlobalContext.js'
import PageWrapper from '../components/PageWrapper'
// import Hero from "../sections/landing8/Hero";
import Hero from '../sections/landing6/Hero'
import TxnApiDemo from '../components/APIDemos/TxnApiDemo'

// import Feature from "../sections/landing1/Feature";
import HomeFeatures from '../sections/landing1/HomeFeatures'

import { Row, Col, Container } from 'react-bootstrap'

import '../addedcss/styles.scss'
import '../addedcss/apiDemo.css'

import ogImage from '../assets/extra/global/link-logo-white.png'
import Differentiation from '../components/Differentiation'
import Fact from '../sections/landing1/Fact.js'
import Differentiationv2 from '../components/Differentiationv2/Differentiationv2.js'
import Usecasesv2 from '../components/Usecasesv2/Usecasesv2.js'
import BeforeAfterImage from '../components/BeforeAfterImage/BeforeAfterImage.js'

import landing from '../assets/image/svg/landing.svg'
import LandingMain from '../components/LandingMain/LandingMain.js'
import ScrollTest from '../components/ScrollTest.js'
import { ScrollContainer, ScrollPage } from 'react-scroll-motion'
import APIDemo from '../components/APIDemos/APIDemo.js'
import TxnApiDemoV2 from '../components/APIDemos/TxnApiDemov2.js'

import OtherCTA from '../components/OtherCTA'
import WhyGenify from '../components/Differentiationv2/WhyGenify.js'
import { Helmet } from 'react-helmet'
import rb2b from '../utils/rb2b'

const IndexPage = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        url: 'https://genify.ai/',
        path: '/',
        page_type: 'homePage',
        event: 'page_view',
        title: 'Genify Homepage',
      })

      // rb2b
      !(function() {
        var reb2b = (window.reb2b = window.reb2b || [])
        if (reb2b.invoked) return
        reb2b.invoked = true
        reb2b.methods = ['identify', 'collect']
        reb2b.factory = function(method) {
          return function() {
            var args = Array.prototype.slice.call(arguments)
            args.unshift(method)
            reb2b.push(args)
            return reb2b
          }
        }
        for (var i = 0; i < reb2b.methods.length; i++) {
          var key = reb2b.methods[i]
          reb2b[key] = reb2b.factory(key)
        }
        reb2b.load = function(key) {
          var script = document.createElement('script')
          script.type = 'text/javascript'
          script.async = true
          script.src =
            'https://s3-us-west-2.amazonaws.com/b2bjsstore/b/' +
            key +
            '/reb2b.js.gz'
          var first = document.getElementsByTagName('script')[0]
          first.parentNode.insertBefore(script, first)
        }
        reb2b.SNIPPET_VERSION = '1.0.1'
        reb2b.load('Y46DJ4HXQP61')
        console.log('reb2b loaded')
      })()
    }
  }, [])

  var parentRef = useRef(null)
  const imgurl = `https://genify.ai${ogImage}`

  return (
    <ScrollContainer>
      <Seo
        title='Genify Categorization & Credit Scoring APIs | Genify'
        image={imgurl}
        description="Genify's flagship and innovative APIs offered to fintechs and banks, for transaction enrichment, credit scoring and beyond. Click for more."
        url='https://www.genify.ai/'
      />

      {/* <Helmet>
        <script
          type='text/javascript'
        >
              {rb2b}
        </script>
      </Helmet> */}

      <PageWrapper
        headerConfig={{
          theme: 'dark',
          align: 'right',
          variant: 'primary',
          isFluid: true,
          button: 'null', //cta, account, null
          source: 'genify',
          path: 'homePage',
        }}
        footerConfig={{
          style: 'style1', //style1, style2
        }}
      >
        {/* <Hero /> */}
        <LandingMain />

        {/* <div ref = {parentRef} >
          <ScrollTest parent = {parentRef} style = {{zIndex:'200'}}/>
        </div> */}
        {/* <ValueProp /> */}
        <hr
          style={{ margin: '0', padding: '1', borderTop: '1px solid #828dbf' }}
        ></hr>

        <div className='inner-banner pt-29 pb-md-2 bg-default-1'>
          <Container>
            <Row className='justify-content-center pt-5'>
              <Col lg='9' xl='8'>
                <div className='px-md-15 text-center'>
                  <h2 className='title gr-text-2 mb-9' style={{}}>
                    Products
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <HomeFeatures />

        <div
          className='inner-banner pt-20 pb-20'
          style={{ backgroundColor: '#fff' }}
        >
          {/* <Container> */}
          {/* <Row className="justify-content-left pt-5"> */}
          {/* <Col lg="9" xl="8"> */}
          <div className=' text-left px-lg-20 pt-12 pt-lg-14'>
            <h2
              className='gr-text-4 mb-7'
              style={{ color: 'rgb(74, 99, 165)' }}
            >
              API products to accelerate your fintech launch
            </h2>
          </div>
          {/* </Col> */}
          {/* </Row> */}
          {/* </Container> */}
          {/* <TxnApiDemo /> */}
          <APIDemo selected='txn' />
        </div>
        <div
          className='inner-banner pt-29  bg-default-1 pb-25 border-top-5'
          style={{ backgroundColor: 'rgb(74, 99, 165)' }}
        >
          <Container>
            <Row className=' pt-5'>
              <Col lg='12' xl='12'>
                <div className='px-md-15 text-center'>
                  <h2 className=' gr-text-2 mb-9' style={{ color: '#fff' }}>
                    Trusted by banks, fintechs, and other companies on 4
                    continents.
                  </h2>
                </div>
                {/* <img
                  className="landing-large-image"
                  src={landing}
                  alt="landing image"
                  style={{ width: "100%", height: "80%" }}
                /> */}
              </Col>
            </Row>
          </Container>

          <div className='px-md-15 text-center mt-20'>
            <h4 className='gr-text-5 mb-9' style={{ color: '#fff' }}></h4>
          </div>
          <Fact />

          {/* <Differentiation /> */}
        </div>

        {/* <Differentiationv2 /> */}
        <WhyGenify />

        <Usecasesv2 />

        <OtherCTA />

        {/* <div className="inner-banner pt-29 pb-md-3 bg-default-1">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="9" xl="8">
                <div className="px-md-15 text-center">
                  <h2 className="title gr-text-2 mb-9" style={{}}>
                    Showcase
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <UseCases /> */}
      </PageWrapper>
    </ScrollContainer>
  )
}
export default IndexPage
