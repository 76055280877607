import React from "react";
import "./LandingMain.css";
import TypeWriter from "typewriter-effect";
import FloatingCard1 from "./txn_images/Aliexpress.webp";
import FloatingCard2 from "./txn_images/Flixbus.webp";
import FloatingCard3 from "./txn_images/Noon.webp";
import FloatingCard4 from "./txn_images/Rei.webp";
import FloatingCard5 from "./txn_images/Muji.webp";
import FloatingCard6 from "./txn_images/Lidl.webp";
import FloatingCard7 from "./txn_images/Tesco.webp";

import { Button } from "react-bootstrap";
import ScrollTest from "../ScrollTest";
import { ScrollContainer } from "react-scroll-motion";
import { Link } from "gatsby";

function LandingMain() {
  return (
    <>
      <div className="top-top-section pt-20">
        <div className="top-section">
          <div className="background-image"></div>
          <img className="floating-image" src={FloatingCard3} alt="Image 1" />
          <img className="floating-image" src={FloatingCard7} alt="Image 2" />
          <img className="floating-image" src={FloatingCard2} alt="Image 3" />
          <img className="floating-image" src={FloatingCard1} alt="Image 4" />
          <img className="floating-image" src={FloatingCard4} alt="Image 4" />

          <h1 className="big-text mb-6">
            {" "}
            <TypeWriter
              style={{ width: "80vw", whiteSpace: "pre-line" }}
              onInit={(typewriter) => {
                typewriter
                  .changeDelay(80)
                  .typeString(
                    "Transaction categorization <br/> and credit scoring APIs."
                  )
                  .start();
              }}
            />
          </h1>
          <div className="buttons-ctr">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="hero-btn pb-10">
                <Button
                  href="txn-data-gui"
                  className="gr-hover-y landing-btn"
                  style={{ backgroundColor: "#ff565b", border: "0" }}
                  // onClick={() =>
                  //   window.dataLayer.push({
                  //     page_type: "homePage",
                  //     event: "request_demo",
                  //   })
                  // }
                >
                  Try Categorization
                </Button>
              </div>
              <div className="hero-btn pb-10">
                <Link
                  to="/contact-us"
                  className="gr-text-color gr-hover-text-white"
                >
                  <Button
                    href="contact-us/#contactform"
                    className="gr-hover-y landing-btn"
                    style={{
                      color: "rgb(74, 99, 165)",
                      backgroundColor: "white",
                      border: "0",
                    }}
                    onClick={() =>
                      window.dataLayer.push({
                        page_type: "homePage",
                        event: "request_demo",
                      })
                    }
                  >
                    Get Started
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div></div>
          {/* <div style={{ height: "0vh" }}>
            <ScrollTest />
          </div> */}
        </div>
      </div>
    </>
  );
}

export default LandingMain;
