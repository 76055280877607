import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CountUp from "react-countup";
import LazyLoad from "react-lazyload";

const Fact = () => (
  <>
    {/* <!-- Fact section --> */}
    <div className="fact-section pt-lg-15 pb-7 pb-lg-24">
      <Container>
        <Row className="justify-content-center">
          <Col lg="4" md="6" sm="6" className="mb-9 mb-lg-0">
            <div className="single-fact text-center px-xl-6">
              <h3 className="title mb-7 gr-text-3" style={{ color: "white" }}>
              <LazyLoad style={{ color: "white" }}>
                  <span className="counter">
                    <CountUp duration={3} end={180} />
                  </span>
                  K+
                </LazyLoad>
              </h3>
              <p
                className="gr-text-8-w mb-0"
                style={{ color: "rgb(255, 86, 91)" }}
              >
                Borrowers scored.
              </p>
            </div>
          </Col>
          <Col lg="4" md="6" sm="6" className="mb-9 mb-lg-0">
            <div className="single-fact text-center px-xl-6">
              <h3 className="title mb-7 gr-text-3">
                <LazyLoad style={{ color: "white" }}>
                  <span className="counter">
                    <CountUp duration={3} end={100} />
                  </span>
                  K+
                </LazyLoad>
              </h3>
              <p className="gr-text-8-w mb-0">Unique merchants enriched.</p>
            </div>
          </Col>
          <Col lg="4" md="6" sm="6" className="mb-9 mb-lg-0">
            <div className="single-fact text-center px-xl-6">
              <h3 className="title mb-7 gr-text-3" style={{ color: "white" }}>
                <LazyLoad>
                  <span className="counter">
                    <CountUp duration={3} end={20} />
                  </span>
                  +
                </LazyLoad>
              </h3>
              <p className="gr-text-8-w mb-0">
                Countries in which coverage has been proven.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </>
);

export default Fact;
