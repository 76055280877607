import React from "react";
// import "./Differentiationv2.css";
import Image from "../../assets/image/ezloan_logo/SVG/Logo_Blue.svg";
import transparency from "../../assets/image/svg/transparency.svg";
import usersegmentation from "../../assets/image/svg/usersegmentation.svg";
import pfm from "../../assets/image/svg/pfm.svg";
import future from "../../assets/image/svg/future.svg";
import gogreen from "../../assets/image/svg/gogreen.svg";
import SourceAgnostic from "../../assets/image/svg/diff/database.svg";
import GlobaCoverage from "../../assets/image/svg/diff/multilingual.svg";
import EasyIntegration from "../../assets/image/svg/diff/easy_integration.svg";
import Turnkey from "../../assets/image/svg/diff/turnkey.svg";
const Differentiationv2 = () => {
  return (
    <section className="diff2-section-1">
      <div className="diff2-section-2">
        <div>
          <p className="section-header">Why Genify?</p>
          <h2 className="section-h2">
            Your partner to unlock value in transaction data.
          </h2>
        </div>
        <div className="diff-grid">
          {" "}
          {/*grid*/}
          <div>
            <div>
              <img
                src={GlobaCoverage}
                style={{ height: "60px",position: "relative" }}
                alt="multilingual"
              />
            </div>{" "}
            {/*img */}
            <div></div> {/*spacer */}
            <h4 className="diff-section-h4 mt-4">Global Coverage</h4>
            <p className="diff-section-p">
            Enrich transactions in English, عربي, русский, 中文 or any other language.

            </p>
          </div>
          <div>
            <img
              src={EasyIntegration}
              style={{ height: "60px", position: "relative" }}
              alt="easy integration"
            />{" "}
            {/*img */}
            <div></div> {/*spacer */}
            <h4 className="diff-section-h4 mt-4">Easy Integration</h4>
            <p className="diff-section-p">
            A single API endpoint, 5 enrichment fields.
            </p>
          </div>
          <div>
            <img
              src={SourceAgnostic}
              style={{ height: "60px", position: "relative" }}
              alt="source agnostic"
            />{" "}
            {/*img */}
            <div></div> {/*spacer */}
            <h4 className="diff-section-h4 mt-4">Source Agnostic</h4>
            <p className="diff-section-p">
            Enrich transactions from any source, including open banking.

            </p>
          </div>
          <div>
            <img
              src={Turnkey}
              style={{ height: "60px", position: "relative" }}
              alt="turn key"
            />{" "}
            {/*img */}
            <div></div> {/*spacer */}
            <h4 className="diff-section-h4 mt-4">Turnkey</h4>
            <p className="diff-section-p">
            Plug and play API. From day 1. No training needed.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Differentiationv2;
